<template>
    <span>
        <template v-if="editing">
            <select v-if="options" @change="submit" v-model="newValue">
                <option v-for="option in options" :key="option.id" :value="option.id">{{ option.name }}</option>
            </select>
            <input v-else v-show="editing" v-model="newValue" @keyup.enter="submit" @blur="submit" ref="theinput"
                v-bind="inputProps">
        </template>
        <label v-show="!editing" class="thelabel" @click="edit">{{ label }}</label>
    </span>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: true
        },
        inputProps: {
            type: Object,
            required: false,
            default: () => ({
                type: "text"
            })
        },
        options: { // if this is passed, it will be used to populate a select dropdown
            type: Array,
            required: false,
            default: null
        }
    },
    data: function () {
        return {
            newValue: '',
            editing: false
        }
    },
    computed: {
        label: vm => {
            if(vm.options) {
                const item = vm.options.find(option => option.id === vm.value)
                return item.name
            }

            return vm.value?.length ? vm.value : '...'
        }
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    watch: {
        value: function (val) {
            this.newValue = val
        }
    },
    methods: {
        edit() {
            this.editing = true

            if(! this.options) {
                this.$nextTick(() => {
                    this.$refs.theinput.focus()
                    this.$refs.theinput.select()
                })
            }
        },
        submit() {
            this.editing = false
            this.$emit('change', this.newValue)
        }
    },
    beforeMount() {
        this.newValue = this.value
    }
}
</script>

<style lang="css" scoped>
.thelabel {
    cursor: pointer;
}

.thelabel:hover {
    text-decoration: underline;
}
</style>