<template>
  <validation-form />
</template>

<script>
// import ManageAuth0Accounts from "@/components/ManageAuth0Accounts.vue";
// import ManageValidationInfo from "@/components/ManageValidationInfo.vue";
import ValidationForm from "@/components/ValidationForm.vue";

export default {
  components: {
    ValidationForm
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
</style>
