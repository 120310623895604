<template>
    <div style="display: flex; align-items: center; flex-direction: column; overflow: auto;">
        <div>
            <label class="note">Auth0 Search -- For Managing Identity Linking</label>
        </div>

        <div class="mb-1 search-bar">

            <label for="user-search" class="mr-1">Search:</label><input v-model="searchText" type="text"
                id="user-search" placeholder="..." @keyup.enter="doSearch" autofocus />
        </div>

        <div v-if="searchResults" class="search-results">
            <Auth0User v-for="user in searchResults" :key="user.key" :user="user" @changed="doSearch(2750)" />
        </div>
    </div>
</template>

<script>
import api from "@/api";
import Auth0User from "@/components/Auth0User.vue";

export default {
    components: { Auth0User },
    data() {
        return {
            loading: false,
            searchText: "",
            searchResults: null
        };
    },
    computed: {
    },
    methods: {
        async doSearch(delay = 0) {
            setTimeout(async () => {
                this.searchResults = null

                this.searchResults = await api.getAuth0Users({
                    q: this.searchText,
                    search_engine: 'v3'
                })

                this.searchResults.forEach(u => {
                    u.key = `${u.user_id}.${u.updated_at}.${u.identities?.length || 0}`
                })
            }, delay)
        },
        mounted() {
        }
    }
}
</script>

<style lang="css" scoped>
.note {
    font-style: italic;
    font-size: smaller;
}

.search-bar {
    line-height: 2.2em;
}

.search-results {
    max-width: 50em;
    display: flex;
    flex-direction: column;
    row-gap: var(--padding);
}
</style>
