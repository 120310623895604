import Vue from 'vue';

async function getVueAppEnv() {
  const response = await fetch('/vue_app_env', {
    method: 'GET'
  });

  return response.json();
}

async function getToken() {
  try {
    return await Vue.prototype.$auth.getTokenSilently();
  }
  catch (e) {
    Vue.prototype.$auth.logout()
  }
}

const getUserInformation = async () => {
  const jwt = await getToken()
  const response = await fetch('/api/account', {
    method: 'GET',
    headers: new Headers({
      Authorization: 'Bearer ' + jwt,
    }),
  });

  return response.json();
};

const getDealersForZip = async (zip5) => {
  const jwt = await getToken()
  const response = await fetch(`/api/dealers/${zip5}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: 'Bearer ' + jwt,
    }),
  });

  return response.json();
};

const getAllDealers = async () => {
  const jwt = await getToken()
  const response = await fetch(`/api/dealers`, {
    method: 'GET',
    headers: new Headers({
      Authorization: 'Bearer ' + jwt,
    }),
  });

  return response.json();
};

const getUsers = async (text) => {
  const jwt = await getToken()

  const urlParams = new URLSearchParams({ text });
  const url = `/api/users?${urlParams.toString()}`

  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      Authorization: 'Bearer ' + jwt,
    })
  });

  return await response.json();
}

const putUserValidation = async (pcUserId, info) => {
  const jwt = await getToken()

  const url = `/api/users/${pcUserId}`

  console.log("! PUTTING:  " + pcUserId)
  console.dir(info)

  const response = await fetch(url, {
    method: 'PUT',
    headers: new Headers({
      Authorization: 'Bearer ' + jwt,
      "Content-Type": 'application/json; charset=UTF-8',
    }),
    body: JSON.stringify(info)
  });

  return await response.json();
}

const getAuth0Users = async (params) => {
  const jwt = await getToken()

  const urlParams = new URLSearchParams(params);
  const url = `/api/auth0/users?${urlParams.toString()}`

  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      Authorization: 'Bearer ' + jwt,
    })
  });

  return await response.json();
};

const postAuth0User = async (json, urlParamsObj) => {
  const jwt = await getToken()

  const response = await fetch(`/api/auth0/users?${new URLSearchParams(urlParamsObj).toString()}`, {
    method: 'POST',
    headers: new Headers({
      Authorization: 'Bearer ' + jwt,
      'Content-Type': 'application/json; charset=UTF-8',
    }),
    body: JSON.stringify(json)
  });

  return response;
}

const postAuth0Identity = async (parentId, childId) => {
  const jwt = await getToken()

  const response = await fetch(`/api/auth0/users/${encodeURIComponent(parentId)}/identities`, {
    method: 'POST',
    headers: new Headers({
      Authorization: 'Bearer ' + jwt,
      'Content-Type': 'application/json; charset=UTF-8',
    }),
    body: JSON.stringify({
      link_with: childId
    })
  });

  return response;
}

const deleteAuth0Identity = async (parentId, provider, childId) => {
  const jwt = await getToken()

  const response = await fetch(`/api/auth0/users/${encodeURIComponent(parentId)}/identities/${provider}/${childId}`, {
    method: 'DELETE',
    headers: new Headers({
      Authorization: 'Bearer ' + jwt,
      'Content-Type': 'application/json; charset=UTF-8',
    })
  });

  return response;
}

const putUserInformation = async (payload) => {
  const jwt = await getToken()

  const response = await fetch('/api/account', {
    method: 'PUT',
    headers: new Headers({
      Authorization: 'Bearer ' + jwt,
      'Content-Type': 'application/json; charset=UTF-8',
    }),
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw response.statusText;
  }

  return response;
};

const deleteUser = async (payload) => {
  const jwt = await getToken()
  const response = await fetch('/api/account', {
    method: 'DELETE',
    headers: new Headers({
      Authorization: 'Bearer ' + jwt,
      'Content-Type': 'application/json; charset=UTF-8',
    }),
    body: JSON.stringify(payload),
  });

  return response;
};

export default {
  getVueAppEnv,
  getUserInformation,
  putUserInformation,
  deleteUser,
  getDealersForZip, getAllDealers,
  getUsers,
  getAuth0Users, postAuth0User,
  postAuth0Identity, deleteAuth0Identity,
  putUserValidation
};
