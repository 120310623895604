<template>
    <div :class="cardClasses">
        <div class="row">
            <img :src="picture" class="user-picture">
            <div>{{ userIdentifier }}</div>
            <div class="user-id" @click="copyUserId">
                {{ userId }}
            </div>
        </div>

        <div class="identities" v-if="identities.length">
            <auth0-user v-for="identity in identities" :key="identity.key" :user="identity" @changed="emitChanged"
                :parent="user"></auth0-user>
        </div>

        <div class="row">
            <a v-if="linkable" @click.stop="linkTo" href="#">Link to...</a>
            <a v-if="phoneAddable" @click.stop="addPhoneLink" href="#">Add Phone...</a>
        </div>
        <div class="row" v-if="isChild">
            <a @click.stop="unlink" href="#">Unlink from Parent</a>
        </div>
    </div>

</template>

<script>
import api from "../api";

export default {
    name: "Auth0User",
    props: ['user', 'parent'],
    emits: ['changed'],
    computed: {
        userIdentifier: vm => {
            const user = vm.user
            console.dir(user)
            if(user.email?.length) {
                return user.email
            }
            
            if(user?.phone_number?.length) {
                return user.phone_number
            }

            if(user?.profileData?.phone_number?.length) {
                return user.profileData.phone_number
            }
            
            return 'N/A'
        },
        cardClasses: vm => {
            if (vm.parent) {
                return "card child"
            }

            return "card"
        },
        isChild: vm => {
            return vm.parent ? true : false
        },
        linkable: vm => {
            return (vm.identities?.length === 0) && (!vm.isChild)
        },
        phoneAddable: vm => {
            if (vm.user.user_id.startsWith("sms")) {
                return false
            }

            return (!vm.isChild) // TOOD: maybe search for the phone already existing?
        },
        identities: vm => {
            const ret = vm.user?.identities?.filter(i => `${i.provider}|${i.user_id}` !== vm.user.user_id) || []

            ret.forEach((i, index) => {
                i.key = `${i.provider}.${i.user_id}.${index}`
            })

            return ret
        },
        userId: vm => {
            return vm.isChild ? vm.user.provider + "|" + vm.user.user_id : vm.user.user_id
        },
        picture: vm => {
            return vm.user.picture || vm.user?.profileData?.picture || vm.parent.picture
        }
    },
    methods: {
        async linkTo() {
            const to = prompt("Enter the parent Auth0 Account Id")
            if (!to) {
                return
            }

            await api.postAuth0Identity(to, this.user.user_id)

            this.$emit("changed")
        },
        async unlink() {
            await api.deleteAuth0Identity(this.parent.user_id, this.user.provider, this.user.user_id)

            this.$emit("changed")
        },
        async addPhoneLink() {
            const phone = prompt("Enter the Phone (e.g. +15012223333)")
            if (!phone) {
                return
            }

            const json = {
                "phone_number": phone,
                "user_metadata": {},
                "blocked": false,
                "email_verified": false,
                "phone_verified": true,
                "app_metadata": {},
                "given_name": this.user.given_name,
                "family_name": this.user.family_name,
                "name": this.user.name,
                "nickname": this.user.nickname,
                "connection": "sms",
                "verify_email": false,
                "username": this.user.username,
                "picture": "https://cdn.auth0.com/avatars/1.png"
            }

            await api.postAuth0User(json, { parentId: this.user.user_id })

            this.$emit("changed")
        },
        emitChanged() {
            this.$emit("changed")
        },
        copyUserId() {
            navigator.clipboard.writeText(this.user.user_id)
        }
    },
    mounted() {
    }
};
</script>

<style lang="css" scoped>
.card {
    background: var(--bg2);
    display: flex;
    flex-direction: column;
    row-gap: 0em;
    padding: var(--padding);
}

.card.child {
    background-color: var(--bg1);
}

a {
    font-style: italic;
}

.row {
    display: flex;
    flex-direction: row;
    column-gap: var(--padding);
    align-items: center;
    align-content: center;
}

.user-id {
    font-style: italic;
    font-size: smaller;
    justify-self: flex-end;
    text-align: right;
    flex-grow: 1;
    cursor: pointer;
    text-decoration: underline;
}

.user-picture {
    width: 2em;
    height: 2em;
}

.identities {
    margin-left: var(--padding);
    overflow: auto;
}
</style>
