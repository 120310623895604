<template>
   <div>
      <a v-show="! shown" href="#" @click.stop="show" class="subtle unvisitable" title="Other Account Options">Other Account Options</a>

      <div v-show="shown">
         <a href="#" @click.stop="logout" class="subtle unvisitable" title="Logout">Logout</a>
      </div>

      <div v-show="shown" class="mt-5">

         <div v-show="! promptForEmail">
            <div>
               <a href="#" @click.stop="beginDeleteAccount" class="subtle unvisitable" title="Permanently Delete Account">Delete Account...</a>
            </div>
            <div>
               <label class="tinylabel"> (All Pipe Planner and Plastic Collections Data Related to Your Account Will Be Permanently Removed -- Including Pending Rebates)</label>
            </div>
         </div>

         <div v-show="promptForEmail" id="verify_div">
            <div style="display: 'flex';">
               <label for="verify_email">Verify Your Email to Proceed to Deletion:</label>
               <br>
               <input id="verify_email" 
                  class="mt-1"
                  name="email" 
                  type="email" 
                  v-model="validationEmail" 
                  size="20"
                  autocomplete="off">
            </div>

            <div class="mt-1" style="display: 'flex';">
               <input type="button" @click="cancelDelete" value="Cancel">
               <input type="submit" class="ml-1 cancel" :disabled="! finalDeleteEnabled" @click="finalDelete" value="Delete">
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import api from '../api';

export default {
  data() {
    return {
      shown: false,
      promptForEmail: false,
      validationEmail: ''
    };
  },
  computed: {
   finalDeleteEnabled: vm => {
      return vm.validationEmail === vm.$auth.user.email;
   }
  },
  methods: {
   show() {
      this.shown = true;
   },
   logout() {
    this.$auth.logout({
      //returnTo: window.location.origin
    })
   },
   beginDeleteAccount() {
      this.promptForEmail = true;
   },
   async finalDelete() {
      await api.deleteUser();

      alert('Your Account Has Been Permanently Removed');

      window.location = 'https://revolutioncompany.com';
   },
   cancelDelete() {
      this.shown = false;
      this.promptForEmail = false;
      this.validationEmail = '';
   }
  },
  mounted() {
  }
};
</script>

<style scoped>
#verify_div {
   display: flex;
   flex-direction: column;
   justify-content: center;
}

#verify_email {
   width: 10em;
}

.cancel {
   background: orange !important;
}

.tinylabel {
   font-size: x-small;
   font-style: italic;
}
</style>
