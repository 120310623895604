<template>
  <div>
    Not Found
  </div>
</template>

<script>
export default {
  mounted() {
  }
};
</script>

<style scoped>
div {
  margin: 0;
}
</style>
