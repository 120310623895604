<template>
    <div class="top" v-show="dealers">
        <div class="mb-1 search-bar">
            <div>
                <label class="note">Search the customer-entered validation info (address, org, etc.)</label>
            </div>
            <label for="user-search" class="mr-1">Search:</label><input v-model="searchText" type="text"
                id="user-search" placeholder="..." @keyup.enter="doSearch" @blur="doSearch" autofocus />
        </div>

        <div v-if="dealers && searchResults" class="search-results">
            <!-- <Auth0User v-for="user in searchResults" :key="user.key" :user="user" @changed="doSearch(2750)" /> -->
            <AdminValidationForm v-for="info in searchResults" :key="info.id" :info="info" :states="states"
                :dealers="dealers">
                {{ info }}
            </AdminValidationForm>
        </div>
    </div>
</template>

<script>
import api from '../api';

import AdminValidationForm from '@/components/AdminValidationForm.vue';

const DefaultDealers = [
    { id: '00000000-0000-0000-0000-000000000000', name: 'No Dealer' },
    { id: '11111111-1111-1111-1111-111111111111', name: 'Unlisted Dealer' }
]

export default {
    components: {
        AdminValidationForm
    },
    props: {

    },
    data() {
        return {
            loading: false,
            searchText: "",
            searchResults: null,
            dealers: null
        };
    },
    computed: {
        states: () => {
            return [
                "AK",
                "AL",
                "AR",
                "AZ",
                "CA",
                "CO",
                "CT",
                "DC",
                "DE",
                "FL",
                "GA",
                "HI",
                "IA",
                "ID",
                "IL",
                "IN",
                "KS",
                "KY",
                "LA",
                "MA",
                "MD",
                "ME",
                "MI",
                "MN",
                "MO",
                "MS",
                "MT",
                "NC",
                "ND",
                "NE",
                "NH",
                "NJ",
                "NM",
                "NV",
                "NY",
                "OH",
                "OK",
                "OR",
                "PA",
                "RI",
                "SC",
                "SD",
                "TN",
                "TX",
                "UT",
                "VA",
                "VT",
                "WA",
                "WI",
                "WV",
                "WY",
            ]
        }
    },
    methods: {
        async fetchDealer() {
            let theDealers = await api.getAllDealers()
            theDealers = theDealers.filter(dealer => dealer.id !== '00000000-0000-0000-0000-000000000000'
                && dealer.id !== '11111111-1111-1111-1111-111111111111'
            )
            theDealers.sort((a, b) => a.name.localeCompare(b.name))

            this.dealers = [...DefaultDealers, ...theDealers]
        },
        async fetchData() {
            if (this.searchText?.length) {
                this.searchResults = await api.getUsers(this.searchText)
            }
            else {
                this.searchResults = []
            }
        },
        async doSearch() {
            this.$router.push({ name: 'admin/validation', query: { q: this.searchText } })
            await this.fetchData()
        }
    },
    beforeMount() {
        this.searchText = this.$route.query.q || ""
    },
    mounted() {
        this.fetchData()
        this.fetchDealer()
    }
}
</script>

<style lang="css" scoped>
.top {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: 100%;
}

.note {
    font-style: italic;
    font-size: smaller;
}

.search-bar {
    line-height: 2.2em;
}

.search-results {
    padding-left: var(--padding);
    padding-right: var(--padding);
    padding-bottom: var(--padding);
    display: flex;
    flex-direction: column;
    overflow: auto;
    row-gap: var(--padding);
}
</style>
