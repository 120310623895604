<template>
  <div id="app">

    <template v-if="isAuthenticated">
      <template v-if="isAdmin">
        <div class="nav-wrapper">
          <nav>

            <router-link :to="{ name: 'profile' }">
              Profile
            </router-link>

            <template v-if="isAdmin">
              <router-link :to="{ name: 'admin/validation' }">
                Validation Admin
              </router-link>
              <router-link :to="{ name: 'admin/linking' }">
                Auth0 Linking
              </router-link>
            </template>

            <!-- <span :class="tabClasses(0)" @click="tabTo(0)">Auth0 Accounts</span>
            <span :class="tabClasses(1)" @click="tabTo(1)">Validation Info</span>
            <span :class="tabClasses(2)" @click="tabTo(2)">My Account</span> -->
          </nav>

          <div class="nav-content">
            <router-view />
            <!-- <manage-auth0-accounts v-if="selectedTabIndex === 0" />

            <manage-validation-info v-if="selectedTabIndex === 1" />

            <validation-form v-if="selectedTabIndex === 2" /> -->
          </div>
        </div>
      </template>
      <template v-else>
        <validation-form />
      </template>
    </template>

  </div>
</template>

<script>
import ValidationForm from '@/components/ValidationForm.vue';

export default {
  components: {
    ValidationForm
  },
  computed: {
    isAuthenticated: vm => vm.$auth.isAuthenticated,
    isAdmin: vm => {
      const roles = vm.$auth?.user['https://deltaplastics.com/roles'] || []
      return roles.some(r => r === 'MyAccount Admin')
    }
  },
};
</script>

<style lang="css">
@import 'assets/style.css';

#app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--dark0);
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.nav-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--bg1);
  padding: var(--padding);
  color: white;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.nav-content {
  flex-grow: 1;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
}

nav {
  background: var(--bg2);
  padding: var(--padding);
  display: flex;
  flex-direction: row;
  column-gap: var(--padding);
  margin-bottom: var(--padding);
}

nav a {
  cursor: pointer;
  text-decoration: underline;
}

nav a.active {
  text-decoration: initial;
  font-weight: bold;
  cursor: initial;
}
</style>
