import Vue from "vue";
import Router from "vue-router";
import { authGuard } from "./auth/authGuard";
import * as auth from './auth';
import NotFound from "@/views/NotFound";
import ProfileView from "@/views/ProfileView";
import ManageAuth0Accounts from '@/views/ManageAuth0Accounts'
import ManageValidationInfo from '@/views/ManageValidationInfo'

Vue.use(Router);

const router = new Router({
  mode: "history",
  linkActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "home",
      redirect: "profile",
    },
    {
      path: "/profile",
      name: "profile",
      component: ProfileView,
    },
    {
      path: "/admin/linking",
      name: "admin/linking",
      component: ManageAuth0Accounts
    },
    {
      path: "/admin/validation",
      name: "admin/validation",
      component: ManageValidationInfo
    },
    {
      path: "*",
      name: "notfound",
      component: NotFound,
    },
  ],
});

const ogPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return ogPush
    .call(this, location)
    .catch((err) =>
      err.name === "NavigationDuplicated"
        ? Promise.resolve()
        : Promise.reject(err)
    );
};

router.beforeEach((to, from, next) => {
  authGuard(to, from, next);
});

let userNameAppended = false
router.afterEach(function () {
  let authInstance = auth.getInstance()
  if ((! userNameAppended) && authInstance.isAuthenticated) {
    document.title += ' - ' + authInstance.user.name
    userNameAppended = true
  }
})

export default router;
