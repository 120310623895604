import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { Auth0 } from './auth';
import Api from './api';

Vue.config.productionTip = false;

async function main() {
  const env = await Api.getVueAppEnv();

  Vue.use(Auth0, {
    domain: env.VUE_APP_AUTH0_DOMAIN,
    clientId: env.VUE_APP_AUTH0_CLIENT_ID,
    audience: env.VUE_APP_AUTH0_API_AUDIENCE,
    redirectUri: window.location.origin,
    onRedirectCallback: (appState) => {
      console.log("Cleaning Up History");
      window.history.replaceState({}, document.title, window.location.pathname);

      const nextRoute =
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname;
      console.log("Redirecting to Next Route: " + nextRoute);

      router.push(nextRoute);
    },
  });

  new Vue({
    router,
    render: (h) => h(App),
  }).$mount("#app");
}

main();
