<template>
  <div v-if="prevUserInfo">
    <form @click="formClickHandler" @submit.stop="submitHandler" id="theform">
      <img class="img_header" src="https://revolutionbranding.blob.core.windows.net/auth0/dual_logo_for_auth.png" />
      <h4>Ag Plastic Dealer</h4>
      <div class="shared">
        <fieldset>
          <legend>Dealer&nbsp;Zip</legend>
          <input :disabled="!edit" size="5" type="text" name="dealer_zip" v-model="userInformation.dealer_zip" />
        </fieldset>

        <fieldset id="dealer_select_fieldset">
          <legend>Dealer</legend>
          <select :disabled="!edit" required="required" id="dealer_select" name="dealer_id"
            :class="userInformation.dealer_id ? '' : 'required'" v-model="userInformation.dealer_id">
            <option v-for="dealer in dealers" :key="dealer.id" :value="dealer.id">
              {{ dealer.name }}
            </option>
          </select>
        </fieldset>
      </div>

      <h4>My Account Information (<span class="user_email">{{ userIdentifier }})</span></h4>
      <fieldset class="row">
        <legend>First Name</legend>
        <input :disabled="!edit" type="text" name="first_name" size="32" v-model="userInformation.first_name"
          required="required" />
      </fieldset>
      <fieldset class="row">
        <legend>Last Name</legend>
        <input :disabled="!edit" type="text" name="last_name" size="32" v-model="userInformation.last_name"
          required="required" />
      </fieldset>
      <fieldset class="row">
        <legend>Phone</legend>
        <input :disabled="!edit" type="tel" name="phone" size="32" v-model="userInformation.phone"
          required="required" />
      </fieldset>
      <fieldset class="row">
        <legend>Organization</legend>
        <input :disabled="!edit" type="text" name="organization" size="32" v-model="userInformation.organization"
          required="required" />
      </fieldset>
      <fieldset class="row">
        <legend>Billing Address</legend>
        <input :disabled="!edit" type="text" name="address1" size="32" v-model="userInformation.address1"
          required="required" />
        <input type="hidden" name="address2" value="" size="32" />
      </fieldset>
      <fieldset class="row">
        <legend>City</legend>
        <input :disabled="!edit" type="text" name="city" v-model="userInformation.city" size="32" required="required" />
      </fieldset>
      <div class="shared">
        <fieldset>
          <legend>State</legend>
          <select :disabled="!edit" name="state" v-model="userInformation.state">
            <option v-for="(state, index) in states" :key="index">
              {{ state }}
            </option>
          </select>
        </fieldset>
        <fieldset>
          <legend>Zip 5</legend>
          <input :disabled="!edit" id="zip" name="zip" size="5" v-model="userInformation.zip" required="required" />
        </fieldset>
      </div>
      <div class="buttons">
        <input id="cancel" type="button" @click.stop="cancelHandler" :value="`${edit ? 'Cancel' : 'Back'}`" />
        <input id="submit" type="submit" @click.stop="submitHandler" :class="edit ? 'active' : ''"
          :value="`${edit ? 'Update' : 'Edit'}`" />
      </div>
    </form>
    <other-account-options class="other_account_options" />
  </div>
</template>

<script>
import api from "../api";
import OtherAccountOptions from "@/components/OtherAccountOptions.vue";

const DefaultDealers = [
  { id: '00000000-0000-0000-0000-000000000000', name: 'No Dealer' },
  { id: '11111111-1111-1111-1111-111111111111', name: 'Unlisted Dealer' }
]

export default {
  name: "ValidationForm",
  components: { OtherAccountOptions },
  data() {
    return {
      userInformation: {
        address1: '',
        address2: '',
        city: '',
        first_name: '',
        last_name: '',
        organization: '',
        phone: '',
        state: '',
        zip: '',
        dealer_zip: null, dealer_id: null, dealer_name: null
      },
      prevUserInfo: null,
      states: [],
      fetchedDealers: [],
      dealerZipCache: {},
      edit: false
    };
  },
  computed: {
    userIdentifier: vm => {
      const user = vm.$auth.user
      return user.email?.length ? user.email : (user.phone_number || 'N/A')
    },
    dealers: vm => {
      const ids = new Set();

      const ret = [];

      const addDealer = d => {
        if (ids.has(d.id)) {
          return;
        }

        ret.push(d);
        ids.add(d.id);
      };

      DefaultDealers.forEach(addDealer);
      vm.fetchedDealers.forEach(addDealer);

      if (vm.userInformation.dealer_id && (!ids.has(vm.userInformation.dealer_id))) {
        addDealer({
          id: vm.userInformation.dealer_id,
          name: vm.userInformation.dealer_name,
          zip5: vm.userInformation.dealer_zip
        })
      }

      if (vm.prevUserInfo && vm.prevUserInfo.dealer_id && (!ids.has(vm.prevUserInfo.dealer_id))) {
        addDealer({
          id: vm.prevUserInfo.dealer_id,
          name: vm.prevUserInfo.dealer_name,
          zip5: vm.prevUserInfo.dealer_zip
        })
      }

      // Sort by name 
      {
        ret.forEach(d => {
          if (!d.name) {
            d.name = 'N/A';
          }
        });

        ret.sort((a, b) => a.name.localeCompare(b.name));
      }

      return ret;
    },
    callbackUrl() {
      const split = window.location.href.split('?');
      if (split.length <= 1) {
        return null;
      }

      const paramString = split[1];
      let params = new URLSearchParams(paramString);

      for (let pair of params.entries()) {
        if (pair[0] === 'callback') {
          return pair[1];
        }
      }

      return null;
    }
  },
  watch: {
    'userInformation.dealer_zip': 'updateFetchedDealers'
  },
  methods: {
    async updateFetchedDealers() {
      const zip = this.userInformation.dealer_zip;
      if (!zip) {
        this.fetchedDealers = [];
        return;
      }

      const zipString = zip.toString();
      if (zipString.length !== 5) {
        this.fetchedDealers = [];
        return;
      }

      const zipNum = Number.parseInt(zipString);
      if (!Number.isFinite(zipNum)) {
        this.fetchedDealers = [];
        return;
      }

      if (zipString in this.dealerZipCache) {
        this.fetchedDealers = this.dealerZipCache[zipString];
        return;
      }

      try {
        const dealers = await api.getDealersForZip(zipString);
        this.fetchedDealers = dealers;
        this.dealerZipCache[zipString] = dealers;
      }
      catch (e) {
        this.fetchedDealers = [];
        console.error(e);
      }
    },
    async getUserInformation() {
      try {
        const { userInformation, states } = await api.getUserInformation();

        if (!states.includes(userInformation.state)) {
          states.splice(0, 0, userInformation.state);
        }

        if (userInformation.dealer_zip === 0) {
          userInformation.dealer_zip = null;
        }

        Object.assign(this.userInformation, userInformation);

        this.prevUserInfo = Object.assign({}, userInformation);
        this.states = states;
      } catch (error) {
        console.error("ValidationForm: ", error);
        this.$auth.logout()
      }
    },
    async putUserInformation(payload) {
      try {
        await api.putUserInformation(payload);
      } catch (error) {
        alert('API Error: ' + error);
      }
    },
    getUserUpdates() {
      const updates = {};
      for (const key in this.userInformation) {
        const val = this.userInformation[key];
        const prevVal = this?.prevUserInfo[key];

        if (val !== prevVal) {
          updates[key] = val;
        }
      }
      return updates;
    },
    formClickHandler() {
      if (!this.edit) {
        this.edit = true;
      }
    },
    async submitHandler(e) {
      e.preventDefault();

      if (!this.edit) {
        this.edit = !this.edit;
        return;
      }

      const updates = this.getUserUpdates();

      if (Object.keys(updates).length) {
        await this.putUserInformation(this.userInformation);
        this.prevUserInfo = JSON.parse(JSON.stringify(this.userInformation));
      }

      this.back();
    },
    cancelHandler() {
      if (this.edit) {
        this.edit = false;
        const prev = this.prevUserInfo || {};
        for (let key in this.userInformation) {
          if (!(key in prev)) {
            delete this.userInformation[key];
          }
          else {
            this.userInformation[key] = prev[key];
          }
        }

        return;
      }

      this.back();
    },
    async back() {
      const callbackUrl = this.callbackUrl;

      if (callbackUrl) {
        window.location = callbackUrl;
      }
      else {
        window.open("", "_self").close();
      }
    }
  },
  mounted() {
    this.getUserInformation();
  }
};
</script>

<style>
form {
  background: white;
  padding: 0.25rem;
  box-shadow: 1px 2px 15px #000;
  width: 88vw;
  max-width: 500px;
  border-radius: 0.25em;
  margin-bottom: 1rem;
}

fieldset {
  padding: 0.25em;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0.25em;
}

input:disabled {
  color: #bbbbbb;
}

input[type="text"] {
  user-select: all;
  -webkit-user-select: all;
}

select {
  padding: 0.75em;
}

.img_header {
  display: block;
  width: 90%;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-direction: row;
}

.row:focus {
  background: #eeeeee;
}

.row:not(:last-child) {
  margin-bottom: 0.5em;
}

.row input {
  width: 100%;
}

.shared {
  display: flex;
  flex-direction: row;
}

.buttons {
  margin-top: 1.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttons input {
  flex-grow: 1;
}

.buttons input:first-child {
  margin-right: 5px;
}

.block {
  display: block;
  margin-bottom: 5px;
}

.user_email {
  font-style: italic;
  font-size: smaller;
}

#dealer_select_fieldset {
  flex-grow: 1;
}

#dealer_select {
  width: 100%;
}

.other_account_options {
  margin-top: calc(var(--padding) * 2);
  max-width: 500px;
}
</style>
