<template>
    <div class="outer">
        <div class="far-edges i mb-1">
            <div>{{ fiendlyIdentifier }}</div>
            <div>{{ userId }}</div>
        </div>


        <div>
            <label class="val-label">Address 1</label>
            <editable-input v-model="address1" @change="change" />
        </div>

        <div>
            <label class="val-label">Address 2</label>
            <editable-input v-model="address2" @change="change" />
        </div>

        <div>
            <label class="val-label">City</label>
            <editable-input v-model="city" @change="change" />
        </div>

        <div>
            <label class="val-label">First Name</label>
            <editable-input v-model="first_name" @change="change" />
        </div>

        <div>
            <label class="val-label">Last Name</label>
            <editable-input v-model="last_name" @change="change" />
        </div>

        <div>
            <label class="val-label">Organization</label>
            <editable-input v-model="organization_name" @change="change" />
        </div>

        <div>
            <label class="val-label">Phone</label>
            <editable-input v-model="phone" @change="change"
                :inputProps="{ type: 'tel', pattern: '[+]?[0-9]{3}-[0-9]{3}-[0-9]{4}' }" />
        </div>

        <div>
            <label class="val-label">State</label>
            <editable-input v-model="state" @change="change" />
        </div>

        <div>
            <label class="val-label">Zip</label>
            <editable-input v-model="zip" @change="change"
                :inputProps="{ type: 'text', size: 5, required: true, pattern: '^\d{5}(-\d{4})?$' }" />
        </div>

        <div>
            <label class="val-label">Dealer</label>
            <editable-input v-model="dealer_id" @change="change" :options="dealers" />
        </div>
    </div>
</template>

<script>
import api from "@/api";
import EditableInput from "@/components/EditableInput.vue";

export default {
    components: { EditableInput },
    props: {
        info: {
            type: Object,
            required: true
        },
        states: {
            type: Array,
            required: true
        },
        dealers: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            address1: '',
            address2: '',
            city: '',
            first_name: '',
            last_name: '',
            organization_name: '',
            phone: '',
            state: '',
            zip: '',
            dealer_id: ''
        };
    },
    computed: {
        fiendlyIdentifier: vm => {
            let ret = `${vm.info.email || ''}`

            if (vm.info.phone) {
                if (ret.length) {
                    ret += ' / ' + vm.info.phone
                }
                else {
                    ret = vm.info.phone
                }
            }

            return ret
        },
        userId: vm => {
            return vm.info.auth0_user_id
        },
        selectedDealer: vm => {
            return vm.dealers.find(d => d.id === vm.info.dealer_id)
        }
    },
    watch: {
    },
    methods: {
        async change() {
            await api.putUserValidation(this.info.id, {
                address1: this.address1,
                address2: this.address2,
                city: this.city,
                first_name: this.first_name,
                last_name: this.last_name,
                organization_name: this.organization_name,
                phone: this.phone,
                state: this.state,
                zip: this.zip,
                dealer_id: this.dealer_id
            })
        }
    },
    beforeMount() { // Critical to use beforeMount() instead of mounted() so that it's passed to child components
        this.address1 = this.info.address1
        this.address2 = this.info.address2
        this.city = this.info.city
        this.first_name = this.info.first_name
        this.last_name = this.info.last_name
        this.organization_name = this.info.organization_name
        this.phone = this.info.phone
        this.state = this.info.state
        this.zip = this.info.zip
        this.dealer_id = this.info.dealer_id
    }
};
</script>

<style lang="css" scoped>
.outer {
    display: flex;
    flex-direction: column;
    border: var(--padding);
    background-color: var(--bg2);
    padding: var(--padding);
}

.far-edges {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.i {
    font-style: italic;
}

.val-label {
    min-width: 10em;
    display: inline-block;
    font-style: italic;
    margin-right: var(--padding);
}
</style>